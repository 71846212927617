<!--
 * @Author: lanxx
 * @Date: 2021-11-11 09:31:38
 * @LastEditTime: 2022-01-17 17:06:21
 * @LastEditors: lanxx
 * @Description: Do not edit
 * @FilePath: \prosperity-garden-ui\src\views\town.vue
-->
<template>
  <div class="container">
    <div class="main">
      <top-header></top-header>
      <div class="content">
        <div class="left">
          <div class="year-total">
            <div class="header">销售</div>
            <div class="list">
              <div class="round-rotate clearfix">
                <div class="icon">
                  <div class="round"></div>
                  <img
                    src="https://zhenongfu.oss-cn-hangzhou.aliyuncs.com/prosperity-garden/assets/icons/bigdata1/chanliang.png"
                  />
                </div>
                <div class="body">
                  <span class="num">{{
                    sales.totalVolume | numFilter(10000)
                  }}</span>
                  <span class="unit">万斤</span>
                  <div class="name">销售量</div>
                </div>
              </div>
              <div class="round-rotate clearfix">
                <div class="icon">
                  <div class="round"></div>
                  <img
                    src="https://zhenongfu.oss-cn-hangzhou.aliyuncs.com/prosperity-garden/assets/icons/bigdata1/xiahsoue.png"
                  />
                </div>
                <div class="body">
                  <span class="num">{{
                    sales.totalAmount | numFilter(10000)
                  }}</span>
                  <span class="unit">万元</span>
                  <div class="name">销售总额</div>
                </div>
              </div>
            </div>
          </div>
          <title2 name="村级概况"></title2>
          <div class="county-total">
            <table
              cellspacing="0"
              cellpadding="0"
              border="0"
              table-layout="fixed"
            >
              <tr>
                <td class="header" width="">菜园</td>
                <td class="header" width="16%">面积（亩）</td>
                <td class="header" width="20%">户数/人数<br />（实时）</td>
                <td class="header" width="20%">销售总额<br />（万元）</td>
                <td class="header" width="20%">
                  已分配收入<br />（万元）
                </td>
                <!-- <td class="header" width="20%">
                  待分配收入<br />（万元）
                </td> -->
              </tr>
              <tr v-for="item in gardenCountByArea" :key="item.areaCode">
                <td>{{ item.gardenName }}</td>
                <td>{{ item.totalArea | numFilter }}</td>
                <td>{{ item.pairedHouseNum }}/{{ item.pairedPeopleNum }}</td>
                <td>{{ item.sales.salesIncome | numFilter(10000) }}</td>
                <td>{{ item.sales.distribute | numFilter(10000) }}</td>
                <!-- <td>{{ item.sales.undistributed | numFilter(10000) }}</td> -->
              </tr>
            </table>
          </div>
        </div>
        <div class="middle">
          <div class="div-static">
            <div class="top">{{ areaName }}</div>
            <div class="bottom">
              <div class="bottom-item">
                <span class="label">总面积（亩）</span>
                <span class="value">{{ gardenInfo.totalArea | numFilter }}</span>
              </div>
              <div class="bottom-item">
                <span class="label">总户数（累计/实时）户</span>
                <span class="value">{{ gardenInfo.totalHouse }}/{{ gardenInfo.pairedHouseNum }}</span>
              </div>
              <div class="bottom-item">
                <span class="label">总人数（累计/实时）人</span>
                <span class="value">{{ gardenInfo.totalPeople }}/{{ gardenInfo.pairedPeopleNum}}</span>
              </div>
            </div>
          </div>
          <div class="map" ref="map"></div>
        </div>
        <div class="right">
          <title2 name="种植概览"></title2>
          <el-carousel :interval="5000" indicator-position="none" height="">
            <el-carousel-item v-for="(arr, index) in plant" :key="index">
              <ul class="plant-list">
                <li v-for="item in arr" :key="item.plantVariety">
                  <div class="name">{{ item.plantVariety }}</div>
                  <div class="num">{{ item.totalPreOutput | numFilter }}斤</div>
                  <div class="bar">
                    <div
                      class="line"
                      :style="{ width: item.width + '%' }"
                    ></div>
                  </div>
                </li>
              </ul>
            </el-carousel-item>
          </el-carousel>
          <title2 name="菜园公益服务"></title2>
          <ul class="garden-list">
            <li>
              <span>菜园</span>
              <span>次数</span>
              <span>时长(h)</span>
              <span>投入(元)</span>
            </li>
            <li>
              <span>合计</span>
              <span>{{ serviceResult.totalNum }}</span>
              <span>{{ serviceResult.totalHour }}</span>
              <span>{{ serviceResult.totalInput | numFilter }}</span>
            </li>
          </ul>
          <ul class="garden-list garden-list-body">
            <vue-seamless-scroll :data="service" :class-option="defaultOption">
              <li
                v-for="item in service"
                :key="item.orgId"
                @click="goGarden(item.orgId)"
              >
                <span>{{ item.gardenName }}</span>
                <span>{{ item.totalNum }}</span>
                <span>{{ item.totalHour }}</span>
                <span>{{ item.totalInput | numFilter }}</span>
              </li>
            </vue-seamless-scroll>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { areaCodetoAreaName } from "@/utils/area";
import { $getJson } from "@/utils/fetch";
import { splitToChunks } from "@/utils/index";
export default {
  name: "county",
  data() {
    return {
      areaName: "",
      gardenInfo: {},
      sales: {},
      gardenCountByArea: [],
      plant: [],
      serviceResult: {},
      service: [],
    };
  },
  methods: {
    getMap(myChart, data) {
      const option = {
        tooltip: {
          formatter: (params) => {
            let str = "";
            if (params.seriesType === "scatter") {
              str =
                params.data.gardenName +
                "<br />面积：" +
                params.data.area +
                "（亩）<br />实时结对户数：" +
                params.data.pairedHouseNum +
                "（户）<br />实时结对人数：" +
                params.data.pairedPeopleNum +
                "（人）<br />" +
                (params.data.salesVolume ? "产量" : "预产量") +
                "：" +
                this.numFilter(params.data.output, 10000) +
                "（万斤）<br />销售总额：" +
                this.numFilter(params.data.salesVolume, 10000) +
                "（万元）";
            } else {
              return params.name;
            }
            return str;
          },
        },
        geo: {
          map: this.areaName,
          aspectScale: 1,
          zoom: 1.1,
          roam: false,
          itemStyle: {
            normal: {
              borderWidth: 0,
              shadowColor: "rgba(0, 176, 176, 0.5)",
              shadowBlur: 2,
              shadowOffsetX: -5,
              shadowOffsetY: 10,
            },
          },
        },
        series: [
          {
            type: "map",
            map: this.areaName,
            aspectScale: 1,
            zoom: 1.1,
            roam: false,
            label: {
              normal: {
                show: true,
                textStyle: {
                  color: "#FFFFFF",
                  fontSize: "12",
                },
              },
              emphasis: {
                textStyle: {
                  color: "#FFFFFF",
                },
              },
            },
            itemStyle: {
              normal: {
                areaColor: "#007FFC",
                borderWidth: 2,
                borderColor: "#32C5FF",
              },
              emphasis: {
                areaColor: "#007FFC",
                shadowColor: "#32C5FF",
              },
            },
          },
          {
            type: "scatter",
            coordinateSystem: "geo",
            data,
          },
        ],
      };
      myChart.setOption(option);
      myChart.hideLoading();
      myChart.on("click", (params) => {
        if (params.seriesType === "scatter") {
          this.goGarden(params.data.orgId);
        }
      });
    },
    goGarden(id) {
      this.$router.push({
        name: "garden",
        params: {
          id,
        },
      });
    },
    getCountyTownDetail(myChart) {
      this.$api.bigdata
        .getCountyTownDetail({
          areaCode: this.$route.params.area,
        })
        .then((res) => {
          let result = res.data;
          if (!result) return;
          const {
            gardenInfo = {},
            sales = {},
            gardenCountByArea = [],
            plant = [],
            service = [],
            gardenLocation = [],
          } = result;
          this.gardenInfo = gardenInfo;
          this.sales = sales;
          this.gardenCountByArea = gardenCountByArea;
          let totalPreOutput = plant.reduce((pre, item) => {
            return pre + Number(item.totalPreOutput);
          }, 0);
          let newPlant = plant.map((item) => {
            return {
              ...item,
              width: Math.ceil((item.totalPreOutput / totalPreOutput) * 100),
            };
          });
          this.plant = splitToChunks(newPlant, 5);
          let serviceResult = {
            totalNum: 0,
            totalHour: 0,
            totalInput: 0,
          };
          service.forEach((item) => {
            serviceResult.totalNum += Number(item.totalNum);
            serviceResult.totalHour += Number(item.totalHour);
            serviceResult.totalInput += Number(item.totalInput);
          });
          this.serviceResult = serviceResult;
          this.service = service;
          let mapData = [];
          gardenLocation.forEach((item) => {
            if (item.latitude && item.longitude) {
              let symbol =
                "https://zhenongfu.oss-cn-hangzhou.aliyuncs.com/prosperity-garden/assets/icons/bigdata1/symbol.png";
              if (item.photo) {
                let photo = JSON.parse(item.photo);
                if (photo.length) {
                  symbol =
                    photo[0].fileUrl + "?x-oss-process=image/resize,h_30,w_30";
                }
              }
              mapData.push({
                value: [item.longitude, item.latitude],
                symbol: "image://" + symbol,
                symbolSize: [60, 60],
                ...item,
              });
            }
          });
          this.getMap(myChart, mapData);
        });
    },
  },
  mounted() {
    this.areaName = areaCodetoAreaName(this.$route.params.area);
    const myChart = this.$echarts.init(this.$refs.map);
    myChart.showLoading({
      text: "加载中...",
      textColor: "#fff",
      maskColor: "transparent",
    });
    $getJson(this.$route.params.area + "000").then((res) => {
      this.$echarts.registerMap(this.areaName, res.data);
      this.getCountyTownDetail(myChart);
    });
  },
};
</script>

<style scoped lang="scss">
.main {
  .content {
    display: flex;
    padding: 24px 46px;
    .left {
      width: 500px;
      .year-total {
        width: 465px;
        height: 200px;
        background: url(https://zhenongfu.oss-cn-hangzhou.aliyuncs.com/prosperity-garden/assets/icons/bigdata1/year_town.png);
        background-size: 100% 100%;
        margin-bottom: 30px;
        .header {
          height: 70px;
          line-height: 70px;
          text-align: center;
          font-size: 24px;
        }
        .list {
          display: flex;
          .round-rotate {
            flex: 1;
            margin: 10px 0;
          }
        }
      }
      .county-total {
        font-size: 18px;
        margin-top: 30px;
        border-top: 1px solid rgba(0, 149, 255, 0.5);
        border-left: 1px solid rgba(0, 149, 255, 0.5);
        table {
          width: 100%;
          tr {
            td {
              text-align: center;
              padding: 11px 0;
              line-height: 1.4;
              border-right: 1px solid rgba(0, 149, 255, 0.5);
              border-bottom: 1px solid rgba(0, 149, 255, 0.5);
              background: rgba(0, 63, 152, 0.4);
              &.header {
                color: #00aaff;
              }
              &:nth-child(1) {
                background: rgba(0, 63, 152, 1);
                text-shadow: 0 0 2px #007ffc;
              }
            }
          }
        }
      }
    }
    .middle {
      flex: 1;
      margin: 0 35px 0 0;
      .div-static {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        background: url(https://zhenongfu.oss-cn-hangzhou.aliyuncs.com/prosperity-garden/assets/icons/bigdata3/header.png)
          no-repeat;
        background-size: 100% 100%;
        height: 200px;
        font-size: 18px;
        .top {
          width: 100%;
          text-align: center;
          font-size: 36px;
          letter-spacing: 3px;
          font-weight: 700;
          background-image: linear-gradient(720deg, white, #face42);
          background-clip: text;
          -webkit-background-clip: text;
          color: transparent;
          text-shadow: 0 0 8px #face42;
        }
        .bottom {
          width: 100%;
          height: 113px;
          display: flex;
          justify-content: space-around;
          .bottom-item {
            width: 280px;
            height: 96px;
            background: url(https://zhenongfu.oss-cn-hangzhou.aliyuncs.com/prosperity-garden/assets/icons/bigdata3/%E6%A4%AD%E5%9C%86%E5%BD%A2%20%2B%20%E6%A4%AD%E5%9C%86%E5%BD%A2%20%E8%92%99%E7%89%88.png)
              no-repeat;
            background-size: 100%;
            margin-top: 10px;
            padding: 14px 30px 8px 30px;
            display: flex;
            justify-content: space-around;
            flex-direction: column;
            .label {
              opacity: 0.7;
              color: #eef8ff;
            }
            .value {
              font-size: 36px;
              color: #eef8ff;
            }
          }
        }
      }
      .map {
        height: 753px;
      }
    }
    .right {
      width: 440px;
      ::v-deep .el-carousel__container {
        height: 294px;
        margin: 30px 0;
      }
      .plant-list {
        overflow: hidden;
        li {
          display: flex;
          padding: 0 16px;
          height: 54px;
          line-height: 54px;
          font-size: 18px;
          background: url(https://zhenongfu.oss-cn-hangzhou.aliyuncs.com/prosperity-garden/assets/icons/bigdata1/plant.png);
          background-size: 100% 100%;
          & + li {
            margin-top: 6px;
          }
          .name {
            width: 130px;
            text-shadow: 0 0 2px #007ffc;
          }
          .num {
            width: 110px;
            color: #00aaff;
          }
          .bar {
            flex: 1;
            .line {
              margin-top: 13px;
              height: 30px;
              width: 50%;
              background-color: #007ffc;
            }
          }
        }
      }
      .garden-list {
        margin: 10px 0 0;
        &.garden-list-body {
          margin: 0;
          height: 422px;
          overflow: hidden;
          li {
            cursor: pointer;
          }
        }
        li {
          font-size: 0;
          &:nth-child(even) {
            background: rgba(0, 63, 152, 0.3);
          }
          span {
            display: inline-block;
            width: 25%;
            font-size: 18px;
            text-align: center;
            line-height: 46px;
            &:nth-child(2) {
              color: #fec700;
            }
            &:nth-child(3) {
              color: #65d05f;
            }
            &:nth-child(4) {
              color: #0095ff;
            }
          }
          border-bottom: 1px solid rgba(0, 149, 255, 0.5);
        }
      }
    }
  }
}
</style>